import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react'
import * as authHelper from 'utils/auth-helper'
import Api from 'utils/api'
import Loader from 'component/loader'

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState()

  const saveAuth = (auth) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    authHelper.removeLocationKey()
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const auth = queryParams.get('auth')
    if (auth) {
      saveAuth({ token: auth })
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (didRequest.current != auth) {
          setShowSplashScreen(true)
          const { response } = await Api('/auth/verify_token', 'get')
          if (response?.data) {
            setCurrentUser(response.data)
          } else logout()
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      didRequest.current = auth
    }

    if (auth && auth.token) {
      requestUser(auth.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [auth])

  return showSplashScreen ? (
    <div className='w-full h-[100vh] flex flex-col items-center justify-center'>
      <Loader />
    </div>
  ) : (
    <>{children}</>
  )
}

export { AuthProvider, AuthInit, useAuth }
