import Button from 'component/Buttons/login-button'
import InputFieldOutlined from 'component/Inputs/dashboard-input'
import { useAuth } from 'context/auth'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import Api from 'utils/api'
import * as yup from 'yup'
import EyeCloseIcon from 'icon/eye-close'
import EyeOpenIcon from 'icon/eye-open'
import { useNavigate } from 'react-router-dom'

const SignupSchema = yup.object({
  firstname: yup.string().required().label('First Name'),
  lastname: yup.string().required().label('Last Name'),
  email: yup.string().email().required().label('Email'),
  account_name: yup.string().required().label('Account Name'),
  password: yup
    .string()
    .required()
    .matches(/^(?=.*[a-z])/, 'password must have atleast one lowercase')
    .matches(/^(?=.*[A-Z])/, 'password must have atleast one uppercase')
    .matches(
      /^(?=.*[!@#\$%\^&\*])/,
      'password must have atleast one special character'
    )
    .matches(/^(?=.*[0-9])/, 'password must have atleast one number')
    .min(8, 'Password must be 8 characters long')
    .label('New Password'),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'Passwords mismatch')
    .label('Confirm Password'),
})

const initState = {
  firstname: '',
  lastname: '',
  email: '',
  account_name: '',
  password: '',
  confirmPassword: '',
}

function SignUp() {
  const { saveAuth } = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [processing, setProcessing] = useState(false)
  const navigate = useNavigate()

  const togglePasswordVisibility = () => setShowPassword(!showPassword)
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword)

  const handleSubmit = async (form) => {
    setProcessing(true)
    const { response } = await Api('/auth/account_signup', 'post', {
      payload: form,
      notify: true,
    })
    setProcessing(false)
    saveAuth(response?.data)
  }

  return (
    <div class=' flex h-[calc(100vh-100px)] flex-wrap items-center justify-center text-neutral-800 '>
      <div class='w-full bg-white '>
        <div class='block rounded-lgshadow-lg '>
          {/* <!-- Left column container--> */}
          <div class='px-4 h-[100vh]'>
            {/* <img src={logoimg} alt='' className='w-[100px] ml-10' /> */}
            <h1 className='mt-10 ml-10 font-bold text-lg italic'>Directory</h1>

            <div class='h-[calc(100vh-100px)]  flex flex-col items-center justify-center'>
              <div class='text-center'>
                <h4 class='mb-12 mt-1 pb-1 text-xl font-semibold'>Sign Up</h4>
              </div>
              <div className='bg-white  rounded-2xl shadow-lg py-6 px-8'>
                <Formik
                  validationSchema={SignupSchema}
                  initialValues={{}}
                  onSubmit={handleSubmit}
                  isInitialValid={false}
                >
                  {({ isValid, values, errors }) => (
                    <Form>
                      {console.log(errors)}
                      <div className='bg-white h-full w-[500px] mx-auto'>
                        <div className='grid grid-cols-2 gap-2'>
                          <div className='my-2'>
                            <InputFieldOutlined
                              label={'First Name'}
                              name={'firstname'}
                            />
                          </div>
                          <div className='my-2'>
                            <InputFieldOutlined
                              label={'Last Name'}
                              name={'lastname'}
                            />
                          </div>
                        </div>
                        <div className='my-2'>
                          <InputFieldOutlined
                            label={'Email address'}
                            name={'email'}
                            required
                          />
                        </div>
                        <div className='my-2'>
                          <InputFieldOutlined
                            label={'Account Name'}
                            name={'account_name'}
                            required
                          />
                        </div>
                        <div className='my-2 grid grid-cols-2 gap-2'>
                          <div className='col-span-1'>
                            <InputFieldOutlined
                              type={showPassword ? 'text' : 'password'}
                              label={'Password'}
                              name={'password'}
                              required
                              EndIcon={
                                <button
                                  type='button'
                                  onClick={togglePasswordVisibility}
                                  className='absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none'
                                >
                                  {showPassword ? (
                                    <EyeCloseIcon />
                                  ) : (
                                    <EyeOpenIcon />
                                  )}
                                </button>
                              }
                            />
                          </div>
                          <div className='col-span-1'>
                            <InputFieldOutlined
                              type={showConfirmPassword ? 'text' : 'password'}
                              label={'Confirm Password'}
                              name={'confirmPassword'}
                              required
                              EndIcon={
                                <button
                                  type='button'
                                  onClick={toggleConfirmPasswordVisibility}
                                  className='absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none'
                                >
                                  {showConfirmPassword ? (
                                    <EyeCloseIcon />
                                  ) : (
                                    <EyeOpenIcon />
                                  )}
                                </button>
                              }
                            />
                          </div>
                        </div>
                        <div className='mt-3'>
                          <Button
                            type={'submit'}
                            processing={processing}
                            disabled={!isValid}
                            label={'Submit'}
                          />
                          <div className='text-sm mt-3 text-left'>
                            Already have an account?{' '}
                            <span
                              className='text-blue-400 cursor-pointer hover:underline'
                              onClick={() => {
                                navigate('/')
                              }}
                            >
                              Login
                            </span>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp
