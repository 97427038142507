import TopHeader from 'component/TopHeader'
import { SuperAdminProvider } from 'context/super-admin'
import React from 'react'
import { Outlet } from 'react-router-dom'

function AdminLayout() {
  return (
    <SuperAdminProvider>
      {/* <Sidebar /> */}
      <div className='flex flex-col'>
        <TopHeader />
        <main className='p-4'>
          <Outlet />
        </main>
      </div>
    </SuperAdminProvider>
  )
}

export default AdminLayout
