import React from 'react'
import { useAuth } from 'context/auth'
import AuthRoutes from './auth-routes'
import AdminRoutes from './admin-routes'
import NoLocationRoutes from './nolocation-routes'
import InvalidAccessRoutes from './unauthorize-routes'
import ChangePassRequiredRoutes from './change-pass-req-routes'
import { ROLES } from 'enums/user'
import AppRoutes from './app-routes'

const Routes = () => {
  const { currentUser } = useAuth()
  const currentRoutes = AppRoutes
  const routes = []

  // PUBLIC
  if (!currentUser) {
    currentRoutes[0].children = routes.concat(AuthRoutes)
    return currentRoutes
  }

  const validRoles = [ROLES.SUPER_ADMIN]

  // PREMISSION DENIED
  if (
    currentUser &&
    !currentUser.roles.some((role) => validRoles.includes(role))
  ) {
    currentRoutes[0].children = routes.concat(InvalidAccessRoutes)
    return currentRoutes
  }

  // // NO AGENCY FOUND
  // if (currentUser && !location) {
  //   currentRoutes[0].children = routes.concat(NoLocationRoutes)
  //   return currentRoutes
  // }

  // // CHANGE PASSWORD REQUIRED
  // if (currentUser && currentUser.change_pass_required) {
  //   currentRoutes[0].children = routes.concat(ChangePassRequiredRoutes)
  //   return currentRoutes
  // }

  // // Subscription Expired Layout
  // if (location?.stripe && location?.stripe?.status !== 'active') {
  //   currentRoutes[0].children = routes.concat(SubscriptionExpiredRoutes)
  //   return currentRoutes
  // }
  if (currentUser) {
    // ADMIN
    currentRoutes[0].children = routes.concat(AdminRoutes())
    return currentRoutes
  }

  return currentRoutes
}

export default Routes
