import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import AdminLayout from 'screens/admin/AdminLayout'
import { Navigate } from 'react-router-dom'

const SettingCustomersField = ComponentLoader(
  lazy(() => import('screens/admin/Customers/ghl-fields-map'))
)

// DASHBOARD
const Dashboard = ComponentLoader(
  lazy(() => import('screens/admin/Dashboard/dashboard'))
)

// PRODUCT
const Product = ComponentLoader(lazy(() => import('screens/admin/Product')))

// CUSTOMER
const Customers = ComponentLoader(
  lazy(() => import('screens/admin/Customers/customers'))
)
const Insight = ComponentLoader(
  lazy(() => import('screens/admin/Customers/view-insight'))
)
const Info = ComponentLoader(
  lazy(() => import('screens/admin/Customers/customer-info'))
)

// SETTINGS
const Profile = ComponentLoader(
  lazy(() => import('screens/admin/Profile/profile'))
)

const Integration = ComponentLoader(
  lazy(() => import('screens/admin/Integration/integration'))
)
const IntegrateGHL = ComponentLoader(
  lazy(() => import('screens/admin/Integration/ghl'))
)
const IntegrateGHLLocation = ComponentLoader(
  lazy(() => import('screens/admin/Integration/ghl-location'))
)
const AgencyInfo = ComponentLoader(
  lazy(() => import('screens/admin/AgencyInfo/agencyinfo'))
)

const AdminRoutes = () => {
  return [
    {
      path: '',
      element: <AdminLayout />,
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          path: 'product',
          children: [
            {
              path: '',
              index: true,
              element: <Product />,
            },
          ],
        },
        {
          path: 'customers',
          children: [
            {
              path: '',
              index: true,
              element: <Customers />,
            },
            {
              path: 'view',
              element: <Insight />,
            },
            {
              path: 'info',
              element: <Info />,
            },
            {
              path: 'settings',
              element: <SettingCustomersField />,
            },
          ],
        },
        {
          path: 'profile',
          element: <Profile />,
        },
        {
          path: 'settings',
          children: [
            {
              path: 'integration',
              element: <Integration />,
            },
            {
              path: 'agencyinfo',
              element: <AgencyInfo />,
            },
            {
              path: '',
              index: true,
              element: <Navigate replace to={'./integration'} />,
            },
            {
              path: '*',
              element: <Navigate replace to={'./integration'} />,
            },
          ],
        },

        {
          path: '',
          index: true,
          element: <Navigate replace to={'./dashboard'} />,
        },
        {
          path: '*',
          index: true,
          element: <Navigate replace to={'./dashboard'} />,
        },
      ],
    },
    {
      path: 'integrations',
      children: [
        {
          path: 'crm/app',
          element: <IntegrateGHL />,
        },
        {
          path: 'crm/app/location',
          element: <IntegrateGHLLocation />,
        },
      ],
    },
    {
      path: '',
      element: <Navigate replace to={'./'} />,
    },
    {
      path: '*',
      element: <Navigate replace to={'./'} />,
    },
  ]
}

export default AdminRoutes
