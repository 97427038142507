import ProfileButton from './profile-button'
import GraphIcon from 'icon/graph'
import HomeIcon from 'icon/home'
import LocationIcon from 'icon/locationicon'
import SettingIcon from 'icon/settingicon'
import TeamIcon from 'icon/team'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const menu = [
  {
    label: 'Settings',
    path: './settings',
    icon: <SettingIcon />,
    key: 'setting',
  },
  // {
  //   label: "Customer",
  //   path: "./customers",
  //   icon: <LocationIcon />,
  //   key: "customer",
  // },
  {
    label: 'Product',
    path: './product',
    icon: <TeamIcon />,
    key: 'product',
  },
  {
    label: 'Dashboard',
    path: './dashboard',
    icon: <GraphIcon />,
    key: 'dashboard',
  },
]

function Item({ icon, path, label }) {
  const [active, setActive] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    setActive(pathname == path.replace('.', ''))
  }, [path, pathname])

  return (
    <Link to={path}>
      <div
        // className='flex items-center mx-2'
        className={`flex items-center p-2 mx-4 rounded-lg 
            ${active ? 'bg-gray-100 ' : 'text-gray-500'}
            ${active ? ' text-gray-900' : 'text-gray-500'}
    
             ${'hover:bg-gray-100'} group`}
      >
        {icon}
        <span className='ml-2'>{label}</span>
      </div>
    </Link>
  )
}

function TopHeader() {
  return (
    <div className='bg-slate-200 p-4 flex justify-end'>
      {menu.map((item) => (
        <Item {...item} />
      ))}
      <div className='pl-5 border-l border-black pt-1'>
        <ProfileButton />
      </div>
    </div>
  )
}

export default TopHeader
